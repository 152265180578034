import { createApp } from 'vue'
import App from './index.vue'
import store from '@/store/index.js'
import '@/assets/global.scss'
import VueLazyload from 'vue-lazyload'

import { setPageRem } from '@/uitl/currencyFunc.js'
setPageRem()

const app = createApp(App)
app.use(store)
app.use(VueLazyload)
//  或者写配置项
// Vue.use(VueLazyload, {
//     preLoad: 1.3,                 // 预载高度比例
//     error: 'dist/error.png',     // 加载失败图片
//     loading: 'dist/loading.gif',   // 加载中
//     attempt: 1
// })
app.mount('#app')