import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  setup() {
    let officeNumber = ref('');
    let linuxNumber = ref('');
    let webNumber = ref('');
    let DIUNumber = ref('');
    let pageActive = ref(false);
    watch(pageActive, newValue => {
      if (newValue) {
        setNumberFunc();
      }
    }, {
      immediate: true
    });
    /**
     * start:起始数据
     * end:最终数据
     * time:动画时间
     * dataName
     * */
    const jumpNumber = (start, end, time, dataName) => {
      dataName['value'] = start;
      let endNum = end;
      let lastNum = ''; //小数
      let digit = 0; //小数位数
      let step = 0; //累加步长
      const len = parseInt(end).toString().length;
      //判断是小数
      if (parseInt(end) !== end) {
        endNum = parseInt(end);
        lastNum = end.toString().split('.')[1];
        digit = lastNum.length;
      }
      const timer = setInterval(() => {
        if (dataName['value'] < endNum - step) {
          step++;
          //小数
          let point = getRandomNum(digit);
          //数据过大，后面几位数随机跳动
          if (len >= 6) {
            let bigNumber = getRandomNum(len - 3);
            const leng = parseInt(start).toString().length;
            const length = leng - bigNumber.length;
            //截取岁随机数前面的数
            const cur = length <= 0 ? parseInt(start) : parseInt(start.toString().slice(0, length));
            start = cur + step + bigNumber + '.' + point;
          } else {
            start = parseInt(start) + step + '.' + point;
          }
          dataName['value'] = parseFloat(start);
        } else {
          clearInterval(timer);
          dataName['value'] = end;
        }
      }, time);
    };
    const getRandomNum = len => {
      let point = '';
      for (let i = 1; i <= len; i++) {
        point = point + parseInt(Math.random() * 10);
      }
      return point;
    };
    const setNumberFunc = () => {
      jumpNumber(0, 1800, 30, officeNumber);
      jumpNumber(0, 11700, 10, linuxNumber);
      jumpNumber(0, 27000, 5, webNumber);
      jumpNumber(0, 4500000, 60, DIUNumber);
    };
    return {
      pageActive,
      setNumberFunc,
      officeNumber,
      linuxNumber,
      webNumber,
      DIUNumber
    };
  }
});