import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/footerCom.vue';
import ApplyCom from '@/components/applyCom.vue';
import ProductNavbar from './components/productNavbar.vue';
import NumberCom from './components/numberCom.vue';
import BolgListCom from './components/BolgListCom.vue';
import DetectionTool from './components/DetectionTool.vue';
import BannarCom from './components/bannarCom.vue';
import AgentMenu from '@/module/index/components/agentMenu.vue';
import { ref, onMounted } from 'vue';
import { wachtPageAni } from '@/uitl/currencyFunc.js';
import AigcCom from './components/aigcCom.vue';
import SafeAgentCom from './components/safeAgentCom.vue';
import CodeAgentCom from './components/codeAgentCom.vue';
import SafeProductAgent from './components/safeProductAgent.vue';
import ModelToCom from './components/modelToCom.vue';
export default {
  components: {
    ModelToCom,
    SafeProductAgent,
    CodeAgentCom,
    SafeAgentCom,
    AigcCom,
    HeaderCom,
    ApplyCom,
    NumberCom,
    ProductNavbar,
    BolgListCom,
    DetectionTool,
    BannarCom,
    FooterCom,
    AgentMenu
  },
  name: 'App',
  setup() {
    const DetectionTool = ref(null);
    const NumberCom = ref(null);
    const ProductNavbar = ref(null);
    const BolgListCom = ref(null);
    const ApplyComRef = ref(null);
    const AgentMenu = ref(null);
    const safeAgentRef = ref(null);
    const codeAgentRef = ref(null);
    const safeProductAgentRef = ref(null);
    const aigcRef = ref(null);
    const modelComRef = ref(null);
    onMounted(() => {
      wachtPageAni([DetectionTool, NumberCom, ProductNavbar, BolgListCom, ApplyComRef, AgentMenu, safeAgentRef, codeAgentRef, safeProductAgentRef, aigcRef, modelComRef]);
    });
    return {
      DetectionTool,
      NumberCom,
      ProductNavbar,
      BolgListCom,
      ApplyComRef,
      AgentMenu,
      safeAgentRef,
      codeAgentRef,
      safeProductAgentRef,
      aigcRef,
      modelComRef
    };
  }
};