import { JUMP_PAGE } from '@/uitl/currencyFunc.js';
import { defineComponent, ref } from 'vue';
import ButtomCom from '@/components/buttomCom.vue';
export default defineComponent({
  components: {
    ButtomCom
  },
  setup() {
    // 判断当前模块是否加载动画
    let pageActive = ref(false);
    let active = ref(1); // 当前激活的标识
    let timer = null;
    // let oldTimer = null
    //鼠标移入，激活
    const changeActive = num => {
      clearInterval(timer);
      timer = null;
      active.value = num;
    };
    const setTime = () => {
      if (!timer) {
        timer = setInterval(() => {
          if (active.value == 3) {
            active.value = 1;
          } else {
            active.value++;
          }
        }, 3000);
      }
    };
    setTime();

    // 了解更多-跳转
    const jumpPage = () => {
      if (active.value == 1) {
        JUMP_PAGE('/codeProduct.html');
      } else if (active.value == 2) {
        JUMP_PAGE('/protocolProduct.html');
      } else {
        JUMP_PAGE('/consultingService.html');
      }
    };
    return {
      outFunc: setTime,
      jumpPage,
      active,
      changeActive,
      pageActive
    };
  }
});