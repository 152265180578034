import "core-js/modules/es.array.push.js";
import ButtomCom from "@/components/buttomCom.vue";
import { blogList } from '@/module/corporateBlog/components/blogs.js';
import { JUMP_PAGE } from '@/uitl/currencyFunc.js';
import { defineComponent, ref } from "vue";
export default defineComponent({
  components: {
    ButtomCom
  },
  setup() {
    const jumpPage = url => {
      window.location.href = url;
    };
    let pageActive = ref(false);

    // 设置首页 博客文章内容
    let News = {},
      Techs = [],
      arr = [];
    for (let i = 0; i < blogList.length; i++) {
      if (blogList[i].blogType === 'news') {
        News = blogList[i];
        break;
      }
    }
    for (let i = 0; i < blogList.length; i++) {
      if (blogList[i].blogType === 'tech' && arr.length <= 3) {
        blogList[i].blogTimeD = blogList[i].blogTime.split('.')[2];
        blogList[i].blogTimeY = blogList[i].blogTime.split('.')[0] + '.' + blogList[i].blogTime.split('.')[1];
        arr.push(blogList[i]);
      }
      if (arr.length === 3) {
        break;
      }
    }
    Techs = arr;

    // 查看博客详情
    const lookInfo = obj => {
      let url = `/blogInfo${obj.blogUUID}.html`;
      window.sessionStorage.setItem('blogTitle', obj.blogTitle);
      url = encodeURI(url);
      JUMP_PAGE(url);
    };
    return {
      News,
      Techs,
      pageActive,
      jumpPage,
      lookInfo
    };
  }
});